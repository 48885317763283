<template>
    <div class="content">
        <h1>Bilar</h1>
        <v-tabs
            color="364557"
        >
            <v-tab to="/cars/compare">
                Jämför bilar
            </v-tab>
            <v-tab to="/cars/rank">
                Ranka bilar
            </v-tab>
        </v-tabs>
        <div class="scrollXContainer">
            <router-view />
        </div>
    </div>
</template>

<script>
import Draggable from 'vuedraggable'
import { mapGetters } from 'vuex'
import Auth from '@/helpers/auth'
const auth = new Auth()
export default {
    data: () => ({
        auth: auth,
        tab: null
    }),
    components: { Draggable },
    mounted() {},
    computed: {
        ...mapGetters({
            list: 'cars/list'
        }),
        cars: {
            get() {
                return this.list
            },
            set(value) {
                return this.$store.commit('cars/set', value)
            }
        }
    },
    methods: {

    }
}
</script>
<style scoped lang="scss">
@import "@/assets/sass/main.scss";
</style>
